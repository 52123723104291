import type { JSX, PropsWithChildren } from 'react';

import { Box } from '@change/design-system/layout';

import { SocialProof } from 'src/app/pages/petition/details/shared/components';

type Props = {
	sticky: boolean;
	visible: boolean;
	hideSocialProof?: boolean;
};

export function ActionButtonWrapper({
	children,
	sticky,
	visible,
	hideSocialProof = false,
}: PropsWithChildren<Props>): JSX.Element {
	if (!sticky) {
		return <Box>{children}</Box>;
	}

	return (
		// this approach allows to show the button if it's focused even if it's hidden due to scrolling
		// which can be especially useful when closing the related modal if scrolling was changed by a buggy screen reader
		// or when user is scrolling while the focus is on the button
		<Box
			sx={{
				bottom: visible ? 0 : '-1000px',
				left: 0,
				position: 'fixed',
				width: '100%',
				zIndex: 2000,
				boxShadow:
					'0px -0.7px 1.4px rgba(0, 0, 0, 0.07), 0px -1.9px 4px rgba(0, 0, 0, 0.05), 0px -4.5px 10px rgba(0, 0, 0, 0.05), 0px -15px 32px rgba(0, 0, 0, 0.04)',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:focus-within': {
					bottom: '0px',
					outline: 'none',
				},
			}}
		>
			<Box pb={8} pt={hideSocialProof ? 8 : 0} px={16} sx={{ bg: 'primary-greyBackground' }}>
				{!hideSocialProof && <SocialProof />}
				{children}
			</Box>
		</Box>
	);
}
