import { useMemo } from 'react';

import { useI18n } from '@change/core/react/i18n';

type Result = Readonly<{
	title: string;
	description: string;
}>;

export function useShareForStartersShareHeadline(channel: string): Result {
	const { translate } = useI18n();

	const title = useMemo(() => {
		switch (channel) {
			case 'facebook':
				return translate('fe.components.petition_share.share_text_fb', {
					// these are to avoid triggering missing replacements reporting
					sharer_name: '%{sharer_name}',
					random_2_digit_number: '%{random_2_digit_number}',
				});
			case 'whatsapp':
				return translate('corgi.share_for_starters.share_text.whatsapp.title', {
					// these are to avoid triggering missing replacements reporting
					sharer_name: '%{sharer_name}',
					random_2_digit_number: '%{random_2_digit_number}',
				});
			case 'copy':
				return translate('corgi.share_for_starters.share_text.copylink.title');
			default:
				return translate('fe.components.petition_share.share_text');
		}
	}, [channel, translate]);
	const description = useMemo(() => {
		switch (channel) {
			case 'facebook':
				return translate('corgi.share_for_starters.share_text.facebook.description', {
					// these are to avoid triggering missing replacements reporting
					signature_target: '%{signature_target}',
					petition_name: '%{petition_name}',
					random_2_digit_number: '%{random_2_digit_number}',
				});
			default:
				return '%{petition_name}';
		}
	}, [channel, translate]);

	return useMemo(
		() => ({
			title,
			description,
		}),
		[title, description],
	);
}
