import type { JSX } from 'react';

import { Translate, TranslatePlural } from '@change/core/react/i18n';
import { ButtonLink } from '@change/design-system/components/actions';
import { Heading, Text } from '@change/design-system/typography';

import { DECISION_MAKER_HASH_QUERY_PARAM_NAME } from 'src/app/pages/petition/details/shared/constants';

import { useDecisionMakerHash } from '../hooks/decisionMakers/decisionMakerHash';
import { usePetitionDetails } from '../hooks/petitionDetails';
import { usePetitionSignatureCount } from '../hooks/petitionStats';

type Props = {
	hideHeading?: boolean;
};

export function RespondContainer({ hideHeading }: Props): JSX.Element {
	const { slug, user } = usePetitionDetails();
	const { signatureCount } = usePetitionSignatureCount();
	const decisionMakerHash = useDecisionMakerHash();

	const responseUrl = decisionMakerHash
		? `/p/${slug}/responses/new?${DECISION_MAKER_HASH_QUERY_PARAM_NAME}=${decisionMakerHash}`
		: `/p/${slug}/responses/new`;

	return (
		<>
			{!hideHeading && (
				<Heading as="h4" size="h4" mb={8}>
					<Translate
						value="fe.components.petition_decision_maker_respond.you_are_decision_maker"
						replacements={{ starter_name: user.firstName }}
					/>
				</Heading>
			)}
			<Text as="div" size="small" color="typography-secondary" mb={16}>
				<TranslatePlural
					value="fe.components.petition_decision_maker_respond.you_can_respond"
					count={signatureCount.displayed}
					replacements={{ signature_count: signatureCount.displayedLocalized }}
				/>
			</Text>
			<ButtonLink to={responseUrl} sx={{ width: '100%' }} mb={16} data-testid="dm-respond-cta-link">
				<Translate value="fe.components.petition_decision_maker_respond.action" />
			</ButtonLink>
			<Text as="div" color="typography-secondary" size="caption">
				<Translate value="fe.components.petition_decision_maker_respond.disclaimer" />
			</Text>
		</>
	);
}
