import { useEffect } from 'react';

import { Translate } from '@change/core/react/i18n';
import { createModalHook, ModalsContainer } from '@change/design-system/modals';

import type { FormValues } from 'src/app/pages/petition/details/shared/types';

import { SignFormContextProvider } from '../../hooks/signFormContext';
import { preloadSignForm, SignFormContainer } from '../signForm';

import { MobileModalContextDecorator } from './shared/MobileModalContextDecorator';
import type { MobileModalContext } from './shared/types';

const PRELOAD_SIGN_FORM_DELAY = 5000; // 5s

export const useMobileSignModal = createModalHook<MobileModalContext, undefined, FormValues>({
	name: 'MobileSignModal',
	variant: 'fullPage',
	closeOnBreakpoints: [false, true],
	title: function MobileSignModalTitle() {
		return <Translate value="fe.components.petition_sign.complete_signature" />;
	},
	body: function MobileSignModalBody({ persistentState, updatePersistentState }) {
		return (
			/* temporary solution to allow the px modal on top of the sign modal */
			/* TODO refactor the workflow to avoid stacking modals on top of each other */
			<ModalsContainer>
				<SignFormContextProvider insideMobileModal>
					<SignFormContainer previousState={persistentState} onChange={updatePersistentState} />
				</SignFormContextProvider>
			</ModalsContainer>
		);
	},
	decorator: ({ children, options }) => (
		<MobileModalContextDecorator {...options}>{children}</MobileModalContextDecorator>
	),
	// just in case we attempt to open it twice (see comment in src/app/pages/petition/details/containers/mobileActionButton/MobileSignButton.tsx)
	killOpenModal: true,
});

export function usePreloadMobileSignModal(): void {
	// preload the sign form chunk after a few seconds
	useEffect(() => {
		const timeout = window.setTimeout(() => {
			void preloadSignForm();
		}, PRELOAD_SIGN_FORM_DELAY);
		return () => window.clearTimeout(timeout);
	}, []);
}
