import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { VisuallyHidden } from '@change/design-system/a11y';
import { iconVictory } from '@change/design-system/icons';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';
import { MembershipAwarenessCard } from 'src/app/shared/components/membershipAwarenessCard';

import { usePetitionDetails } from '../../hooks/petitionDetails';
import { ShareContainer } from '../share';
import { SignatureStatsVictoryContainer } from '../stats/signatureStats';

import { SidePetitionStatus } from './components/SidePetitionStatus';

export function SideVictoryContainer(): JSX.Element {
	const { isVerifiedVictory } = usePetitionDetails();
	const {
		data: {
			petition: { id: petitionId },
		},
	} = usePetitionDetailsPageData();

	return (
		<>
			<SidePetitionStatus icon={iconVictory} iconColor="primary-changeRed">
				{isVerifiedVictory ? (
					<Translate value="fe.pages.petition_show.status.confirmed_victory" />
				) : (
					<Translate value="fe.pages.petition_show.status.victory" />
				)}
			</SidePetitionStatus>
			<SignatureStatsVictoryContainer />
			<MembershipAwarenessCard petitionId={petitionId} />
			<VisuallyHidden as="h2">
				<Translate value="fe.components.petition_share.action" />
			</VisuallyHidden>
			<ShareContainer forMobileView={false} />
		</>
	);
}
