import type { JSX } from 'react';

import { withPrefetchedData } from '@change/core/react/prefetch';
import { useKnownAvailableParams } from '@change/core/react/router';

import type { PetitionTabProps } from 'src/app/pages/petition/shared/tabTypes';

import { ShareHeadlineProvider, useDefaultShareHeadline, useRouteGuestSharer } from '../hooks/shareHeadline';

import { CommonPetitionDetails } from './CommonPetitionDetails';

export const PetitionDetailsFacebookGuest = withPrefetchedData(
	({ userInfoState }: PetitionTabProps): JSX.Element => {
		const { slug } = useKnownAvailableParams<{ slug: string; sharerDisplayName: string; sharerLocation: string }>();

		const { title, description } = useDefaultShareHeadline();
		const sharer = useRouteGuestSharer();

		if (!sharer) {
			return <CommonPetitionDetails slug={slug} userInfoState={userInfoState} />;
		}

		return (
			<ShareHeadlineProvider sharer={sharer} title={title} description={description}>
				<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
			</ShareHeadlineProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [CommonPetitionDetails],
	},
);
