import { useTracking } from '@change/core/react/tracking';
import { openWindow } from '@change/core/window';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';
import { useCurrentUserIdAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';
import { craftSupporterEmail } from 'src/app/shared/utils/decisionMaker';

type Result = ModelHookResult<
	{ emailHref: string; showContactCTA: boolean },
	{
		handleEmailDmClick: () => void;
		handlePhoneDmClick: () => void;
	}
>;

type UseContactDmArgs = {
	displayName: string;
	contactInfo: { email: string | null; phone: string | null } | null;
};

export function useContactDM({ contactInfo, displayName }: UseContactDmArgs): Result {
	const {
		data: {
			petition: {
				slug,
				ask,
				user: { displayName: starterDisplayName },
			},
		},
	} = usePetitionDetailsPageData();

	const currentUserState = useCurrentUserIdAsync();
	const viewerId = isLoaded(currentUserState) ? currentUserState.value : undefined;

	const track = useTracking();

	const handleEmailDmClick = () => {
		void track('petition_page_email_dm_click', {
			user_id: viewerId,
		});
	};

	const handlePhoneDmClick = () => {
		void track('petition_page_phone_dm_click', {
			user_id: viewerId,
		});

		openWindow(`tel:${contactInfo?.phone}`);
	};

	const emailHref = craftSupporterEmail({
		dmEmail: contactInfo?.email,
		dmName: displayName,
		ask,
		slug,
		starterDisplayName,
	});

	const showContactCTA = !!contactInfo?.email || !!contactInfo?.phone;

	return {
		data: {
			emailHref,
			showContactCTA,
		},
		actions: {
			handleEmailDmClick,
			handlePhoneDmClick,
		},
	};
}
