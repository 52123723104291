/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';

import { ClientRender } from '@change/core/react/ssr/render';
import { Box, Flex } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { usePetitionDetailsPageFcm } from 'src/app/pages/petition/details/pageContext';
import type { SupporterVoicesData } from 'src/app/pages/petition/details/pageContext';
import { UserIsInVariantOrStaffOrInSet } from 'src/app/shared/components/access';

import { usePetitionDetails } from '../../hooks/petitionDetails';
import { useSupporterVideos } from '../../hooks/supporterVoices/supporterVideos';

import { CreateVideo } from './components/CreateVideo';
import { VideoSkeleton } from './components/LoadingSkeleton';
import { VideoComponent } from './components/VideoComponent';
import { ViewPetitionFeed } from './components/ViewPetitionFeed';

export function SupporterVoicesComponent({
	supporterVoicesData,
}: {
	supporterVoicesData: SupporterVoicesData;
}): React.JSX.Element | null {
	const { supporterVoicesEnabled } = supporterVoicesData;
	const {
		data: { currentlyPlaying, supporterVideos, showCreateVideo },
		actions: { setCurrentlyPlaying, removeVideoFromState },
	} = useSupporterVideos({ supporterVoicesData });

	const { id: petitionId, slug } = usePetitionDetails();
	const { petitionFeedAccess } = usePetitionDetailsPageFcm();

	if (supporterVideos.length === 0) return null;

	if (!supporterVoicesEnabled) {
		return <Box sx={{ display: 'none' }} data-testid="petition-page-supporters-voices-hidden"></Box>;
	}

	return (
		<Box data-testid="petition-page-supporters-voices">
			<Heading as="h2" sx={{ fontSize: '24' }} my={18}>
				Top supporter voices
			</Heading>
			<Box
				sx={{
					// This creates a scrolling carousel.
					maxWidth: '100%',
					overflowX: 'auto',
					overflowY: 'hidden',
					position: 'relative',
				}}
			>
				<ClientRender placeholder={<VideoSkeleton videos={supporterVideos} />}>
					<Flex
						sx={{
							gap: 16,
						}}
					>
						{supporterVideos?.map((video) => {
							return (
								<VideoComponent
									video={video}
									currentlyPlaying={currentlyPlaying}
									key={video.id}
									setCurrentlyPlaying={setCurrentlyPlaying}
									removeVideoFromState={removeVideoFromState}
								/>
							);
						})}
						{showCreateVideo && <CreateVideo />}
					</Flex>
				</ClientRender>
			</Box>

			{/* TODO replace with a check on a boolean set on pageUserData to avoid CLS */}
			<ClientRender>
				<UserIsInVariantOrStaffOrInSet featureAccessConfigValue={petitionFeedAccess} variant="feed">
					<ViewPetitionFeed petitionId={petitionId} slug={slug} />
				</UserIsInVariantOrStaffOrInSet>
			</ClientRender>
		</Box>
	);
}
