import type { JSX } from 'react';

import { withPrefetchedData } from '@change/core/react/prefetch';
import { useKnownAvailableParams } from '@change/core/react/router';

import type { PetitionTabProps } from 'src/app/pages/petition/shared/tabTypes';

import { CommonPetitionDetails } from './CommonPetitionDetails';

export const PetitionDetails = withPrefetchedData(
	({ userInfoState }: PetitionTabProps): JSX.Element => {
		const { slug } = useKnownAvailableParams<{ slug: string }>();
		return <CommonPetitionDetails slug={slug} userInfoState={userInfoState} />;
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [CommonPetitionDetails],
	},
);
