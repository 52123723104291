import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type {
	PetitionHeadlineBanditData,
	PetitionMitaAlternateImageData,
} from 'src/app/pages/petition/details/shared/types';

import type {
	PetitionDetailsPageCopyLinkBanditVariantQuery,
	PetitionDetailsPageCopyLinkBanditVariantQueryVariables,
	PetitionDetailsPageFacebookBanditVariantQuery,
	PetitionDetailsPageFacebookBanditVariantQueryVariables,
	PetitionDetailsPageWhatsAppBanditVariantQuery,
	PetitionDetailsPageWhatsAppBanditVariantQueryVariables,
	PetitionMitaAlternateImageQuery,
	PetitionMitaAlternateImageQueryVariables,
} from './bandits.graphql';

export async function getMitaAlternateImage(
	slugOrId: string,
	experimentName: string,
	variantName: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PetitionMitaAlternateImageData | undefined> {
	const { data } = await fetch<PetitionMitaAlternateImageQuery, PetitionMitaAlternateImageQueryVariables>({
		query: gql`
			query PetitionMitaAlternateImage($slugOrId: String!, $experimentName: String!, $variantName: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					bandits {
						id
						mitaAlternateImage(experimentName: $experimentName, variantName: $variantName) {
							url
							width
							height
						}
					}
				}
			}
		`,
		variables: { slugOrId, experimentName, variantName },
	});
	return (data?.petition?.bandits.mitaAlternateImage || undefined) as PetitionMitaAlternateImageData | undefined;
}

export async function getFacebookBanditVariantText(
	slugOrId: string,
	variantName: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PetitionHeadlineBanditData | undefined> {
	const { data } = await fetch<
		PetitionDetailsPageFacebookBanditVariantQuery,
		PetitionDetailsPageFacebookBanditVariantQueryVariables
	>({
		query: gql`
			query PetitionDetailsPageFacebookBanditVariant($slugOrId: String!, $variantName: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					bandits {
						id
						facebookHeadline {
							id
							variants {
								variant: activeVariantByName(name: $variantName) {
									data
								}
							}
						}
					}
				}
			}
		`,
		variables: { slugOrId, variantName },
	});
	return (data?.petition?.bandits.facebookHeadline.variants?.variant?.data || undefined) as
		| PetitionHeadlineBanditData
		| undefined;
}

export async function getWhatsappBanditVariantText(
	slugOrId: string,
	variantName: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PetitionHeadlineBanditData | undefined> {
	const { data } = await fetch<
		PetitionDetailsPageWhatsAppBanditVariantQuery,
		PetitionDetailsPageWhatsAppBanditVariantQueryVariables
	>({
		query: gql`
			query PetitionDetailsPageWhatsAppBanditVariant($slugOrId: String!, $variantName: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					bandits {
						id
						whatsappHeadline {
							id
							variants {
								variant: activeVariantByName(name: $variantName) {
									data
								}
							}
						}
					}
				}
			}
		`,
		variables: { slugOrId, variantName },
	});
	return (data?.petition?.bandits.whatsappHeadline.variants?.variant?.data || undefined) as
		| PetitionHeadlineBanditData
		| undefined;
}

export async function getCopylinkBanditVariantText(
	slugOrId: string,
	variantName: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PetitionHeadlineBanditData | undefined> {
	const { data } = await fetch<
		PetitionDetailsPageCopyLinkBanditVariantQuery,
		PetitionDetailsPageCopyLinkBanditVariantQueryVariables
	>({
		query: gql`
			query PetitionDetailsPageCopyLinkBanditVariant($slugOrId: String!, $variantName: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					bandits {
						id
						copylinkHeadline {
							id
							variants {
								variant: activeVariantByName(name: $variantName) {
									data
								}
							}
						}
					}
				}
			}
		`,
		variables: { slugOrId, variantName },
	});
	return (data?.petition?.bandits.copylinkHeadline.variants?.variant?.data || undefined) as
		| PetitionHeadlineBanditData
		| undefined;
}

export async function getHeadlineBanditVariantText(
	shareType: string,
	slugOrId: string,
	variantName: string,
	utilityContext: UtilityContext,
) {
	switch (shareType) {
		case 'wa':
			return getWhatsappBanditVariantText(slugOrId, variantName, utilityContext);
		case 'cl_':
			return getCopylinkBanditVariantText(slugOrId, variantName, utilityContext);
		case 'fb':
			return getFacebookBanditVariantText(slugOrId, variantName, utilityContext);
		default:
			return undefined;
	}
}
