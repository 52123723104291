import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import { getContactDmConfig } from 'src/app/pages/petition/details/shared/api';

import type {
	PetitionDetailsPageDecisionMakersQuery,
	PetitionDetailsPageDecisionMakersQueryVariables,
} from './decisionMakers.graphql';

export type DecisionMakers = NonNullable<
	PetitionDetailsPageDecisionMakersQuery['petition']
>['decisionMakersConnection']['nodes'];

export type DecisionMaker = DecisionMakers[0];

const QUERY = gql`
	query PetitionDetailsPageDecisionMakers($slugOrId: String!, $numDecisionMakers: Int!, $dmContactsToFetch: [String]) {
		petition: petitionBySlugOrId(slugOrId: $slugOrId) {
			id
			decisionMakersConnection(first: $numDecisionMakers, dmContactsToFetch: $dmContactsToFetch) {
				nodes {
					id
					slug
					displayName
					title
					publiclyVisible
					photo {
						id
						userMedium {
							url
							processing
						}
					}
					contactInfo {
						email
						phone
					}
				}
			}
		}
	}
`;

export async function getDecisionMakers(
	petitionSlugOrId: string,
	numDecisionMakers: number,
	utilityContext: UtilityContext,
): Promise<DecisionMakers> {
	const {
		gql: { fetch },
	} = utilityContext;

	const { dmContactsToFetch } = await getContactDmConfig(utilityContext);

	const { data } = await fetch<PetitionDetailsPageDecisionMakersQuery, PetitionDetailsPageDecisionMakersQueryVariables>(
		{
			query: QUERY,
			variables: {
				slugOrId: petitionSlugOrId,
				numDecisionMakers,
				dmContactsToFetch,
			},
		},
	);

	if (!data?.petition?.decisionMakersConnection) throw new Error('Unable to fetch decision makers');

	return data.petition.decisionMakersConnection.nodes;
}
