import { type JSX } from 'react';

import { FakeLink, Link } from '@change/design-system/components/actions';
import { Icon } from '@change/design-system/components/icon';
import { iconEmail, iconPhoneIphone } from '@change/design-system/icons';
import { Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { useContactDM } from '../../../hooks/decisionMakers/useContactDM';

type ContactDmProps = {
	displayName: string;
	contactInfo: { email: string | null; phone: string | null } | null;
};

export function ContactDM({ contactInfo, displayName }: ContactDmProps): JSX.Element {
	const {
		data: { emailHref },
		actions: { handleEmailDmClick, handlePhoneDmClick },
	} = useContactDM({ displayName, contactInfo });

	return (
		<>
			<Text size="caption" fontWeight="bold" as="p" mb={4}>
				Make your petition known to {displayName}
			</Text>
			{emailHref && (
				<Flex>
					<Flex sx={{ width: '50px', justifyContent: 'center' }} mr={10}>
						<Icon icon={iconEmail} />
					</Flex>
					<Text as="p" size="caption">
						<Link to={emailHref} variant="primary" onClick={handleEmailDmClick}>
							Send them an email
						</Link>
					</Text>
				</Flex>
			)}
			{contactInfo?.phone && (
				<Flex>
					<Flex sx={{ width: '50px', justifyContent: 'center' }} mr={10}>
						<Icon icon={iconPhoneIphone} />
					</Flex>
					<Text as="p" size="caption">
						<FakeLink variant="primary" onClick={handlePhoneDmClick}>
							Give them a call
						</FakeLink>
					</Text>
				</Flex>
			)}
		</>
	);
}
