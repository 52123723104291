import { useState } from 'react';
import type { JSX } from 'react';

import type { PrefetchedDataProps } from '@change/core/react/prefetch';
import { withPrefetchedData } from '@change/core/react/prefetch';
import { useKnownAvailableParams } from '@change/core/react/router';

import type { Sharer } from 'src/app/pages/petition/details/shared/types';
import type { PetitionTabProps } from 'src/app/pages/petition/shared/tabTypes';

import { ShareHeadlineProvider, useDefaultShareHeadline } from '../hooks/shareHeadline';

import { getSharer } from './api';
import { CommonPetitionDetails } from './CommonPetitionDetails';

type PrefetchedData = Readonly<{
	sharer?: Sharer;
}>;

export const PetitionDetailsFacebook = withPrefetchedData(
	({ userInfoState, prefetchedData }: PetitionTabProps & PrefetchedDataProps<PrefetchedData>): JSX.Element => {
		// this allows us to conserve the sharer after first render
		const [sharer] = useState<Sharer | undefined>(prefetchedData?.sharer);
		const { slug } = useKnownAvailableParams<{ slug: string }>();

		const { title, description } = useDefaultShareHeadline();

		if (!sharer) {
			return <CommonPetitionDetails slug={slug} userInfoState={userInfoState} />;
		}

		return (
			<ShareHeadlineProvider sharer={sharer} title={title} description={description}>
				<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
			</ShareHeadlineProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [CommonPetitionDetails],
		prefetchData: async (context) => {
			const {
				params: { sharerUserId },
			} = context;
			if (!sharerUserId) return {};
			try {
				return { sharer: await getSharer(sharerUserId, context.utilityContext) };
			} catch (e) {
				return {};
			}
		},
	},
);
