import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { iconLock, iconVictory } from '@change/design-system/icons';
import { Box } from '@change/design-system/layout';

import { usePetitionDetails } from '../../hooks/petitionDetails';

import { PetitionStatusHeader } from './components/PetitionStatusHeader';

function PetitionStatusHeaderContainerInner() {
	const { status, isVerifiedVictory } = usePetitionDetails();

	if (status === 'VICTORY') {
		return (
			<PetitionStatusHeader icon={iconVictory} color="primary-changeRed">
				{isVerifiedVictory ? (
					<Translate value="fe.pages.petition_show.status.confirmed_victory" />
				) : (
					<Translate value="fe.pages.petition_show.status.victory" />
				)}
			</PetitionStatusHeader>
		);
	}

	if (status === 'CLOSED') {
		return (
			<PetitionStatusHeader icon={iconLock} color="neutral-grey600">
				<Translate value="fe.pages.petition_show.status.closed" />
			</PetitionStatusHeader>
		);
	}

	return null;
}

export function PetitionStatusHeaderContainer(): JSX.Element {
	return (
		<Box sx={{ display: ['block', 'none'] }}>
			<PetitionStatusHeaderContainerInner />
		</Box>
	);
}
