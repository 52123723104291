import type { PropsWithChildren } from 'react';

import { Link } from '@change/design-system/components/actions';
import { Icon } from '@change/design-system/components/icon';
import { iconBlock, iconCheckCircleOutline } from '@change/design-system/icons';
import { Box, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import type { DecisionMaker } from 'src/app/pages/petition/details/shared/types';

import { useDmStatus } from '../../../hooks/decisionMakers/useDmStatus';
import type { AllDmResponses } from '../hooks/useDecisionMakers';

function IconWrapper({ children }: PropsWithChildren<EmptyProps>) {
	return (
		<Flex sx={{ width: '50px', justifyContent: 'center' }} mr={10}>
			{children}
		</Flex>
	);
}

export function DmStatus({
	decisionMaker,
	allDmResponses,
}: {
	decisionMaker: DecisionMaker;
	allDmResponses: AllDmResponses;
}): JSX.Element {
	const {
		data: { notificationStatus, responseStatus, responseUrl },
	} = useDmStatus(decisionMaker, allDmResponses);

	return (
		<Box mb={8}>
			<Flex sx={{ alignItems: 'center' }} data-testid="dm-notification-status">
				{notificationStatus ? (
					<>
						<IconWrapper>
							<Icon icon={iconCheckCircleOutline} size="l" />
						</IconWrapper>
						<Text size="small">Notification sent</Text>
					</>
				) : (
					<>
						<IconWrapper>
							<Icon icon={iconBlock} size="l" />
						</IconWrapper>
						<Text size="small">Notification not sent</Text>
					</>
				)}
			</Flex>
			<Flex sx={{ alignItems: 'center' }} data-testid="dm-response-status">
				{responseStatus ? (
					<>
						<IconWrapper>
							<Icon icon={iconCheckCircleOutline} size="l" />
						</IconWrapper>
						<Link
							to={responseUrl}
							data-testid="response-status-link"
							sx={{
								fontSize: '14px',
							}}
						>
							DM has responded
						</Link>
					</>
				) : (
					<>
						<IconWrapper>
							<Icon icon={iconBlock} size="l" />
						</IconWrapper>
						<Text size="small">DM has not responded</Text>
					</>
				)}
			</Flex>
		</Box>
	);
}
