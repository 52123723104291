import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Avatar } from '@change/design-system/components/media';
import { Box, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { ResponsiveBox } from 'src/app/pages/petition/details/shared/components';
import { AfterMobileActionButtonWaypoint } from 'src/app/pages/petition/details/shared/waypoints';

import { usePetitionDetails } from '../../hooks/petitionDetails';
import { MobileActionButtonContainer } from '../mobileActionButton';
import { SignatureStatsContainer } from '../stats/signatureStats';
import { SupporterVoices } from '../supportersVoices/SupportersVoices';

import { DecisionMakerContent } from './components/DecisionMakerContent';
import { StarterLink } from './components/StarterLink';

const AVATAR_SIZE = 44;

export function MetaAttributes(): JSX.Element {
	const { createdAtLocalized, primaryDecisionMakerConnection, organization, user } = usePetitionDetails();

	const { totalCount: decisionMakerCount } = primaryDecisionMakerConnection;
	const hasDecisionMakers = decisionMakerCount > 0;

	const photo = organization ? organization.photo?.organizationSmall : user.photo?.userSmall;
	const photoUrl = photo?.processing ? undefined : photo?.url;

	return (
		<>
			<Text color="typography-secondary" size="small">
				<Flex>
					<Box sx={{ flex: ['2', '1'] }}>
						<Translate value="corgi.petition.details.started_at" />
					</Box>
					<Box sx={{ flex: '4' }}>{createdAtLocalized}</Box>
				</Flex>
				{hasDecisionMakers && (
					<Flex>
						<Box sx={{ flex: ['2', '1'] }}>
							<Translate value="corgi.petition.details.petition_to" />
						</Box>
						<Box sx={{ flex: '4' }}>
							<DecisionMakerContent decisionMakers={primaryDecisionMakerConnection} />
						</Box>
					</Flex>
				)}
			</Text>

			<ResponsiveBox viewportSize="small" mt={16}>
				<SignatureStatsContainer />
			</ResponsiveBox>

			<ResponsiveBox viewportSize="small">
				<MobileActionButtonContainer sticky={false} />
				<AfterMobileActionButtonWaypoint />
			</ResponsiveBox>

			<SupporterVoices />

			<h2>
				<Translate value="corgi.petition.details.body_title" />
			</h2>

			<Flex sx={{ gap: 8 }} mb={16} mt={24}>
				<Avatar variant="subtle" alt="" src={photoUrl} size={AVATAR_SIZE} />
				<Flex
					sx={{
						flex: 1,
						// min-height + center so that 1 line is centered on the avatar
						// and 3+ lines are aligned with the avatar at the top
						minHeight: AVATAR_SIZE,
						alignItems: 'center',
					}}
				>
					<Text
						sx={{
							// half the avatar height, so that 2 lines match the avatar's height
							lineHeight: `${AVATAR_SIZE / 2}px`,
						}}
						color="typography-secondary"
					>
						<Translate value="corgi.petition.details.started_by" />
						&nbsp;
						<StarterLink user={user} organization={organization} />
					</Text>
				</Flex>
			</Flex>
		</>
	);
}
