import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type { Sharer } from 'src/app/pages/petition/details/shared/types';

import type { PetitionDetailsPageSharerInfoQuery, PetitionDetailsPageSharerInfoQueryVariables } from './sharer.graphql';

export async function getSharer(id: string, { gql: { fetch } }: UtilityContext): Promise<Sharer | undefined> {
	const { data } = await fetch<PetitionDetailsPageSharerInfoQuery, PetitionDetailsPageSharerInfoQueryVariables>({
		query: gql`
			query PetitionDetailsPageSharerInfo($id: ID!) {
				user: userById(id: $id) {
					id
					displayName
					shortDisplayName
					formattedLocationString
				}
			}
		`,
		variables: { id },
	});
	return data?.user || undefined;
}
