import { useEffect } from 'react';

import pick from 'lodash/fp/pick';

import { useTracking } from '@change/core/react/tracking';

import { useQueryString } from 'src/app/shared/hooks/location';

import { useCaptchaEnabled } from '../captcha';
import { usePetitionDetails } from '../petitionDetails';
import { useUserInfo } from '../userInfo';

import type { TrackingPetitionStatus } from './shared/petitionStatus';
import { useTrackingPetitionStatus } from './shared/petitionStatus';

type ViewEventQueryParams =
	| 'source_location'
	| 'algorithm'
	| 'grid_position'
	| 'original_footer_petition_id'
	| 'fb_ref'
	| 'fb_action_types'
	| 'recruit_variation'
	| 'recruit_context';
type ViewEventQueryParamsProp = Readonly<Partial<Record<ViewEventQueryParams, string>>>;
type ViewEventProps = Readonly<{
	petition_id: string;
	signed: boolean;
	promotable: boolean;
	petition_status: TrackingPetitionStatus;
	captcha: boolean;
	video: boolean;
	movement_id: string;
	own_petition: boolean;
	signature_count: number;
	petition_strength_score: number;
	show_sign: boolean;
	category_tags: string[];
	geo_reach?: string;
	relevant_location_localized_name?: string;
}> &
	ViewEventQueryParamsProp;

function queryParams(query: ViewEventQueryParamsProp) {
	const gridPosition = !!query.grid_position && query.grid_position.match(/^\d+$/) ? query.grid_position : undefined;

	return {
		grid_position: gridPosition,
		...pick([
			'source_location',
			'algorithm',
			'original_footer_petition_id',
			'fb_ref',
			'fb_action_types',
			'recruit_variation',
			'recruit_context',
		])(query),
	};
}

export function useTrackPageView(): void {
	const {
		id,
		promotable,
		movements,
		score,
		signatureState: { signatureCount },
		tagsConnection,
		geoReach,
		relevantLocationLocalizedName,
	} = usePetitionDetails();
	const movementId = movements.nodes[0]?.id;
	const { signed, ownPetition } = useUserInfo();
	const track = useTracking();

	const captcha = useCaptchaEnabled();
	const query = useQueryString();
	const status = useTrackingPetitionStatus();

	useEffect(() => {
		void track<ViewEventProps>('petition_view', {
			petition_id: `petition_${id}`,
			signed,
			movement_id: movementId,
			promotable,
			petition_status: status,
			captcha,
			video: false,
			signature_count: signatureCount.displayed,
			own_petition: ownPetition,
			petition_strength_score: score,
			show_sign: query.show_sign === 'true',
			category_tags: tagsConnection?.nodes?.map((tag) => tag.name) ?? [],
			geo_reach: geoReach ?? undefined,
			relevant_location_localized_name: relevantLocationLocalizedName ?? undefined,
			...queryParams(query),
		});
		// only send the view event once per id
		// for instance, we don't want a change in the query params to trigger a new event
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);
}
