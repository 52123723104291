import { useState } from 'react';
import type { JSX } from 'react';

import type { PrefetchedDataProps } from '@change/core/react/prefetch';
import { withPrefetchedData } from '@change/core/react/prefetch';
import { useKnownAvailableParams } from '@change/core/react/router';

import type {
	PetitionHeadlineBanditData,
	PetitionMitaAlternateImageData,
	Sharer,
} from 'src/app/pages/petition/details/shared/types';
import type { PetitionTabProps } from 'src/app/pages/petition/shared/tabTypes';

import { MitaProvider } from '../hooks/mita';
import { ShareHeadlineProvider, useDefaultShareHeadline } from '../hooks/shareHeadline';

import { getHeadlineBanditVariantText, getMitaAlternateImage, getSharer } from './api';
import { CommonPetitionDetails } from './CommonPetitionDetails';

type PrefetchedData = Readonly<{
	sharer?: Sharer;
	mita?: PetitionMitaAlternateImageData;
	headlineVariant?: PetitionHeadlineBanditData;
}>;

const ALLOWED_SHARE_TYPES = ['wa', 'cl_', 'fb', 'tw'];

export const PetitionDetailsBanditV2 = withPrefetchedData(
	({ userInfoState, prefetchedData }: PetitionTabProps & PrefetchedDataProps<PrefetchedData>): JSX.Element => {
		// this allows us to conserve the sharer after first render
		const [sharer] = useState<Sharer | undefined>(prefetchedData?.sharer);
		const [headlineVariant] = useState<PetitionHeadlineBanditData | undefined>(prefetchedData?.headlineVariant);
		const [mita] = useState<PetitionMitaAlternateImageData | undefined>(prefetchedData?.mita);
		const { slug } = useKnownAvailableParams<{ slug: string; sharerDisplayName: string; sharerLocation: string }>();

		const { title, description } = useDefaultShareHeadline();

		if (!sharer) {
			if (mita) {
				return (
					<MitaProvider image={mita}>
						<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
					</MitaProvider>
				);
			}
			return <CommonPetitionDetails slug={slug} userInfoState={userInfoState} />;
		}

		if (!headlineVariant) {
			// uses default share headline data
			if (mita) {
				return (
					<MitaProvider image={mita}>
						<ShareHeadlineProvider sharer={sharer} title={title} description={description}>
							<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
						</ShareHeadlineProvider>
					</MitaProvider>
				);
			}
			return (
				<ShareHeadlineProvider sharer={sharer} title={title} description={description}>
					<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
				</ShareHeadlineProvider>
			);
		}

		if (mita) {
			// TO-DO: Refactor this, not sure if this scenario is possible
			return (
				<MitaProvider image={mita}>
					<ShareHeadlineProvider
						sharer={sharer}
						title={headlineVariant.headline}
						description={headlineVariant.description}
					>
						<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
					</ShareHeadlineProvider>
				</MitaProvider>
			);
		}
		return (
			<ShareHeadlineProvider sharer={sharer} title={headlineVariant.headline} description={headlineVariant.description}>
				<CommonPetitionDetails slug={slug} userInfoState={userInfoState} />
			</ShareHeadlineProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [CommonPetitionDetails],
		prefetchData: async (context) => {
			const {
				params: { slug, shareType, experimentName, experimentVariantName, sharerUserId },
			} = context;

			if (!shareType || !ALLOWED_SHARE_TYPES.includes(shareType)) return {};

			if (!experimentVariantName || !sharerUserId) return {};
			return {
				headlineVariant: await getHeadlineBanditVariantText(
					shareType,
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					slug!,
					experimentVariantName,
					context.utilityContext,
				).catch(() => undefined),
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				mita: await getMitaAlternateImage(slug!, experimentName!, experimentVariantName, context.utilityContext).catch(
					() => undefined,
				),
				sharer: await getSharer(sharerUserId, context.utilityContext).catch(() => undefined),
			};
		},
	},
);
