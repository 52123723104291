import { animatePulseStyles } from '@change/design-system/animations';
import { Box, Flex } from '@change/design-system/layout';

import { usePetitionDetailsPageUserData } from 'src/app/pages/petition/details/pageContext';

import type { SupporterVideosList } from '../../../../hooks/supporterVoices/types';

export function UnitSkeleton() {
	const {
		data: { isGuest },
	} = usePetitionDetailsPageUserData();
	return (
		<Box
			sx={{
				height: isGuest ? 200 : 244,
				minWidth: 220,
				background: 'primary-greyBackground',
				borderRadius: 'standard',
			}}
			mr={16}
		></Box>
	);
}

export function VideoSkeleton({ videos }: { videos: SupporterVideosList }) {
	return (
		<Flex sx={{ ...animatePulseStyles() }} mb={8}>
			{videos.map((v) => (
				<UnitSkeleton key={v.id} />
			))}
		</Flex>
	);
}
