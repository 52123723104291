import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type { MediaHitsQuery, MediaHitsQueryVariables } from './index.graphql';

export async function fetchMediaHits(petitionId: string, utilityContext: UtilityContext) {
	const {
		gql: { fetch },
	} = utilityContext;

	const { data } = await fetch<NonNullable<MediaHitsQuery>, MediaHitsQueryVariables>({
		query: gql`
			query MediaHits($petitionId: ID!, $pageOffset: Int!, $pageSize: Int!) {
				getMediaHits(petitionId: $petitionId, pageOffset: $pageOffset, pageSize: $pageSize) {
					isLastPage
					numberOfStarterMedia
					mediaHits {
						id
						url
						mediaSource
					}
				}
			}
		`,
		variables: {
			petitionId,
			pageOffset: 0,
			pageSize: 10,
		},
		important: true,
	});

	return data?.getMediaHits;
}
