import type { JSX } from 'react';

import { Box } from '@change/design-system/layout';

import { SocialProof } from 'src/app/pages/petition/details/shared/components';
import { SignatureCount } from 'src/app/shared/components/signatures';

import { usePetitionSignatureCount } from '../../../hooks/petitionStats';

export function SignatureStatsActiveContainer(): JSX.Element {
	const { signatureCount, signatureGoal } = usePetitionSignatureCount();

	return (
		<Box pt={[0, 8]}>
			<SignatureCount
				count={signatureCount.displayed}
				goal={signatureGoal.displayed}
				localizedCount={signatureCount.displayedLocalized}
				localizedGoal={signatureGoal.displayedLocalized}
			/>
			<Box marginTop={8}>
				<SocialProof />
			</Box>
		</Box>
	);
}
